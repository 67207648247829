<template>
  <OurPage data-cy="users-page" :title="$t('title.dealersCompany._')" gray class="users-page">
    <CompanyList
      :items="companies"
      :pagination-data="paginationData"
      @pageChange="getData"
      @onClickRow="onClickRow"
    />

    <FilterModal :show.sync="isShownFilterModal" />

    <template #header-right>
      <div class="buttons">
        <OurButton
          variant="thirdary"
          filled
          :text="$t('button.filters')"
          @click="onClickFilterButton"
        >
          <OurSvgIcon v-if="isMobileDevice" :src="icons.FilterAltFill" />
        </OurButton>
      </div>
    </template>
  </OurPage>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { allCompaniesQuery, companiesQuery, managersQuery, allCustomersQuery } from "./gql/queries";
import CompanyList from "./_components/CompanyList.vue";
import FilterModal from "../filter/Modal";

export default {
  name: "DealerCompany",

  components: { CompanyList, FilterModal },

  mixins: [apolloMixin],

  data() {
    return {
      companies: [],
      paginationData: {},
      isShownFilterModal: false,
    };
  },

  computed: {
    ...mapState("dealerCompany", ["filtersForm"]),
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    ...mapGetters("user", ["userProfile", "isUserDealer", "isUserDealerAdmin"]),
    ...mapGetters("dealerCompany", ["getStatus", "getFiltersOptions"]),

    addRoute() {
      return { name: "DealerCompanyAdd" };
    },

    isShownAddButton() {
      return (this.isUserDealer && this.isUserDealerAdmin) || !this.isUserDealer;
    },

    icons: () => ({
      FilterAltFill: require("@material-symbols/svg-400/outlined/filter_alt-fill.svg"),
    }),
  },

  watch: {
    filtersForm: {
      handler: "onChangeFiltersForm",
      deep: true,
    },
  },

  async created() {
    await this.getData();
    await this.getAccessGroupsAndManagers();
  },

  beforeDestroy() {
    this.SET_FILTERS_FORM({});
  },

  methods: {
    ...mapMutations("dealerCompany", ["SET_FILTERS_FORM", "SET_MANAGERS", "SET_COMPANIES"]),
    ...mapMutations("dealerUsers", ["SET_CUSTOMERS"]),

    async getAccessGroupsAndManagers() {
      const { oneCEmployees } = await this.$get(managersQuery);

      if (oneCEmployees) {
        this.SET_MANAGERS(oneCEmployees);
      }

      const { allCompanies } = await this.$get(allCompaniesQuery);

      if (allCompanies) {
        this.SET_COMPANIES(allCompanies);
      }
    },
    async getData(page) {
      const { allCustomer } = await this.$get(allCustomersQuery);

      this.SET_CUSTOMERS(allCustomer);

      if (page && page === this.paginationData.currentPage) return;

      const filtersOptions = this.getFiltersOptions();

      const { companies } = await this.$get(companiesQuery, { page, filtersOptions });

      if (companies) {
        this.companies = companies.data.map((company) => {
          const { id, name, isActive, taxNumber, oneCEmployee, customer } = company;

          return {
            id,
            company: name,
            user: oneCEmployee?.name,
            customer: customer,
            taxNumber: taxNumber,
            bonusProgramLevel: customer?.bonusProgramLevel?.name,
            status: this.getStatus(isActive ? "activated" : "deactivated"),
            page: "DealerCompanyEdit",
          };
        });

        this.paginationData = companies.paginatorInfo;
      }
    },

    onClickFilterButton() {
      this.isShownFilterModal = true;
    },

    onChangeFiltersForm() {
      this.getData();
    },

    onClickRow(id) {
      this.$router.push({ name: "DealerCompanyEdit", params: { id } });
    },
  },
};
</script>

<style lang="postcss" scoped>
.users-page {
  .buttons {
    @apply flex items-center justify-between gap-4 sm:justify-start;
  }
}
</style>
