<template>
  <OurCard class="order-card mb-4" border-radius="sm">
    <div class="order-card-wrapper" @click="onClick">
      <div v-if="status" class="flex w-full items-center justify-between">
        <OurTag class="status" size="md" :text="status.text" :color="status.color" />
        <div v-if="publishedAt" class="text-sm text-gray-500">{{ publishedAt }}</div>
        <div v-if="id" class="flex flex-col items-end">
          <div class="text-sm text-gray-500">{{ `#${id}` }}</div>
          <div
            v-if="getPermission['dealer.impersonate'] && isDealerUsers"
            class="relative flex h-8 w-8 items-center justify-center"
            @click.stop="onClickHelperMenu(id)"
          >
            <OurSvgIcon :src="icons.more" />

            <div v-if="isShownMenuBlock === id" class="menu-block">
              <a href="" @click.prevent="loginImpersonateHandler(id)">
                {{ $t("page.users.enter") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <OurTextField v-if="name" :label="$t('page.customers.title')" :value="name" />
      <OurTextField v-if="company" :label="$t('page.dealersCompany.company')" :value="company" />
      <OurTextField v-if="companyTitle" :label="$t('label.companyTitle')">
        <div class="flex flex-col">
          <span>{{ companyTitle }}</span>
        </div>
      </OurTextField>
      <OurTextField v-if="companyCode" :label="$t('label.taxNumber')">
        <div class="flex flex-col">
          <span>{{ companyCode }}</span>
        </div>
      </OurTextField>
      <OurTextField
        v-if="accessGroup"
        :label="$t('page.customers.accessGroup')"
        :value="accessGroup ? accessGroup : '-'"
      />
      <OurTextField v-if="companies.length" :label="$t('page.customers.companies')">
        <div class="flex flex-wrap">
          <div v-for="comp in companies" :key="comp.id" class="dealer-name me-3">
            {{ comp.name }}
            <span>,</span>
          </div>
        </div>
      </OurTextField>
      <OurTextField v-if="dealers.length" :label="$t('page.customers.users')">
        <div class="flex flex-wrap">
          <div v-for="dealer in dealers" :key="dealer.dealer" class="dealer-name me-3">
            {{ dealer.firstName }} {{ dealer.lastName }}
            <span>,</span>
          </div>
        </div>
      </OurTextField>
      <OurTextField v-if="level" :label="$t('page.customers.bonusProgramLevel')" :value="level" />
      <OurTextField v-if="user" class="w-fit" :label="$t('page.dealersCompany.user')">
        <div>{{ user ? user : "-" }}</div>
      </OurTextField>
      <OurTextField
        v-if="taxNumber"
        :label="
          $route.name === 'NewApplications'
            ? $t('label.personalTaxNumber')
            : $t('page.dealersCompany.taxNumber')
        "
      >
        <div>{{ taxNumber ? taxNumber : "-" }}</div>
      </OurTextField>
      <OurTextField v-if="employee || visit" :label="$t('page.users.user')">
        <div class="flex flex-col">
          <span>{{ employee.primaryRow }}</span>
          <span class="text-xs text-gray-500">{{ visit }}</span>
        </div>
      </OurTextField>
      <OurTextField v-if="employee" :label="$t('page.users.role')" :value="employee.secondaryRow" />
      <OurTextField v-if="contacts?.phone || contacts?.email" :label="$t('page.users.contacts')">
        <div class="flex flex-col">
          <span>{{ contacts?.phone }}</span>
          <span class="underline">{{ contacts?.email }}</span>
        </div>
      </OurTextField>
      <OurTextField v-if="customer" :label="$t('page.users.customer')" :value="customer" />
      <OurTextField
        v-if="comment"
        class="text-ellipse"
        :label="$t('page.orders.comment')"
        :value="comment"
      />
    </div>
  </OurCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CustomersCard",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    status: {
      type: Object,
      default: () => {},
    },
    id: {
      type: [String, Number],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    level: {
      type: String,
      default: "",
    },
    company: {
      type: String,
      default: "",
    },
    companyTitle: {
      type: String,
      default: "",
    },
    companyCode: {
      type: String,
      default: "",
    },
    accessGroup: {
      type: String,
      default: "",
    },
    taxNumber: {
      type: String,
      default: "",
    },
    user: {
      type: String,
      default: "",
    },
    publishedAt: {
      type: String,
      default: "",
    },
    companies: {
      type: Array,
      default: () => [],
    },
    dealers: {
      type: Array,
      default: () => [],
    },
    employee: {
      type: Object,
      default: () => {},
    },
    contacts: {
      type: Object,
      default: () => {},
    },
    customer: {
      type: String,
      default: "",
    },
    visit: {
      type: String,
      default: "",
    },
    comment: {
      type: String,
      default: "",
    },
    isDealerUsers: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isShownMenuBlock: null,
    };
  },

  computed: {
    ...mapGetters("user", ["getPermission"]),

    icons: () => ({
      more: require("@material-symbols/svg-400/outlined/more_horiz-fill.svg"),
    }),
  },

  created() {
    window.addEventListener("click", (event) => this.closeMenuBlock(event));
  },

  destroyed() {
    window.removeEventListener("click", (event) => this.closeMenuBlock(event));
  },

  methods: {
    ...mapActions(["loginImpersonate"]),

    async loginImpersonateHandler(val) {
      await this.$router.push({ name: "Dashboard" });
      await this.loginImpersonate(val);
      this.$router.go();
    },

    onClick() {
      this.$emit("clickOnOrder");
    },
    closeMenuBlock(event) {
      if (!this.$el.contains(event.target)) {
        this.isShownMenuBlock = false;
      }
    },
    onClickHelperMenu(val) {
      if (this.isShownMenuBlock === val) {
        this.isShownMenuBlock = null;

        return;
      }

      this.isShownMenuBlock = val;
    },
  },
};
</script>

<style scoped lang="postcss">
.order-card {
  .order-card-wrapper:deep {
    @apply flex flex-col items-start space-y-4;

    .status {
      @apply w-auto;
    }
  }
}

.dealer-name:last-child {
  span {
    display: none;
  }
}

.menu-block {
  @apply absolute right-0 top-6;
  @apply h-auto;
  @apply rounded-lg border border-gray-700 bg-gray-900 text-white;
  @apply py-1;
  @applu flex flex-col;

  a {
    @apply block whitespace-nowrap px-3 py-2;

    &:hover {
      @apply bg-gray-800;
    }
  }
}
</style>
